import axios from 'axios';

const coursesApi = {

	getCoursesByStudentId: async (id: number) => {
		return axios.get('/teacher/student/' + id)
			.then(response => response.data as TeacherCourseDTO[]);
	},
	getUnitesByStudentIdAndCourseId: async (studentId: number, courseId: number) => {
		return axios.get(`/teacher/student/${studentId}/course/${courseId}/units`)
			.then(response => response.data as TeacherCourseDTO);
	},
	postStudentActivityStatus: async (studentId: number, courseactivityId: number, status: string) => {
		return axios.post('/teacher/courseactivitystatus', { CourseActivityId: courseactivityId, StudentId: studentId, Status: status })
			.then(response => response.data as boolean);
	},
	getUnassignedCoursesByStudentId: async (studentId: number) => {
		return axios.get(`/teacher/student/${studentId}/unassignedcourses`)
			.then(response => response.data as TeacherCourseDTO[]);
	},
	postAssignCoursesToStudent: async (studentId: number, courseIds: number[]) => {
		return axios.post(`/teacher/student/${studentId}/assigncourses`, {
			CourseIds: courseIds,
			StudentId: studentId,
		});
		//.then(response => response.data as boolean);
	}
};

export default coursesApi;