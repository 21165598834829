/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Container, DataTable, ModalOverlay } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconChevronRight, IconClose, IconSave } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';
import { Link, BlockerFunction, useBlocker } from 'react-router-dom';
import coursesApi from 'api/coursesApi';

interface IStudentCoursesProps {
	isManageCoursesOpen: boolean;
	setManageCoursesOpen: (value: boolean) => void;
	studentId: string;
}

const StudentCourses = forwardRef((props: IStudentCoursesProps, ref) => {
	const {
		studentId,
		setManageCoursesOpen,
		isManageCoursesOpen,
	} = props;

	const { t } = useTranslation();

	const [courses, setCourses] = useState<TeacherCourseDTO[]>([]);
	const [unassignedCourses, setUnassignedCourses] = useState<TeacherCourseDTO[]>([]);
	const [loadingCourse, setLoading] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

	const fetchCourseByStudentId = async (studentId: number) => {
		try {
			setLoading(true);
			if (studentId) {
				const data = await coursesApi.getCoursesByStudentId(studentId);
				setCourses(data);
				const unassignedCoursesResponse = await coursesApi.getUnassignedCoursesByStudentId(studentId);
				setUnassignedCourses(unassignedCoursesResponse);
			} else {
				setCourses([]);
			}
			setLoading(false);
		} catch (error) {
			console.error('Error fetching students: ', error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCourseByStudentId(parseInt(studentId));
	}, [studentId]);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty]);

	const handleBeforeUnload = (e: any) => {
		if (isDirty) {
			e.preventDefault();
		}
	};

	const shouldBlock = useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) =>
			isDirty && currentLocation.pathname !== nextLocation.pathname,
		[isDirty]
	);

	const blocker = useBlocker(shouldBlock);

	const onAddCourseClick = (rowInfo: RowInfo) => {
		setIsDirty(true);
		setCourses([...courses, rowInfo.original]);
		setUnassignedCourses(unassignedCourses.filter(course => course.id !== rowInfo.original.id));
	};

	const onRemoveCourseClick = (rowInfo: RowInfo) => {
		setIsDirty(true);
		setUnassignedCourses([...unassignedCourses, rowInfo.original]);
		setCourses(courses.filter(course => course.id !== rowInfo.original.id));
	};

	const onCancelClick = () => {
		if (isDirty) {
			setShowWarningModal(true);
		} else {
			setManageCoursesOpen(false);
		}
	};

	const onSaveClick = async () => {
		try {
			setLoading(true);
			const courseIds = courses.map(course => course.id);
			await coursesApi.postAssignCoursesToStudent(parseInt(studentId), courseIds);
			fetchCourseByStudentId(parseInt(studentId));
			setLoading(false);
			setIsDirty(false);
			setManageCoursesOpen(false);
		} catch (error) {
			console.error('Error saving courses: ', error);
			setLoading(false);
		}
	};

	useImperativeHandle(ref, () => ({
		onCancelClick
	}));

	const columns = (isAdd: boolean = false) => {
		return ([
			{
				Header: t('courses.course_id'),
				accessor: 'id',
				show: false
			},
			{
				Header: t('courses.code'),
				accessor: 'courseNumber',
				width: 150,
			},
			{
				Header: t('courses.course_name'),
				accessor: 'courseName',
			},
			{
				accessor: 'Actions',
				wrapStyle: 'wrap',
				width: 150,
				Cell: (rowInfo: RowInfo) =>
					<React.Fragment>
						{!isManageCoursesOpen ?
							<Link
								className="btn-sm btn-ghost-primary p-1"
								to={`${rowInfo.original.id}/units?tab=1`}
							>
								{t('view')}
								<IconChevronRight />
							</Link>
							:
							isAdd ?
								<Button
									className="btn-ghost-primary me-n3 btn-sm"
									onClick={() => onAddCourseClick(rowInfo)}
									icon={<IconAdd className="icon-22" />} />
								:
								<Button
									className="btn-ghost-primary me-n3 btn-sm"
									onClick={() => onRemoveCourseClick(rowInfo)}
									icon={<IconClose className="icon-22" />} />
						}

					</React.Fragment>,
				Filter: <React.Fragment />,
				Header: '',
				id: 'Actions'
			},
		]);
	};

	return (
		<React.Fragment>
			<div className="container-fluid d-flex flex-column flex-grow-1">
				<div className={'row bg-quaternary-400'}>
					<div className="col-6 d-flex align-items-center">
						<h6>{t('courses.students_courses')}</h6>
					</div>
					<div className={`col-6 d-flex align-items-center ${isManageCoursesOpen ? 'justify-content-between border-start' : 'justify-content-end'}`}>
						{isManageCoursesOpen
							? <React.Fragment>
								<h6>{t('courses.all_courses')}</h6>
								<Button onClick={onSaveClick} className="btn-primary btn-sm my-1" icon={<IconSave />} style={{ padding: '4.25px' }}>{t('save')}</Button>
							</React.Fragment>
							: <Button
								className="btn-ghost-primary me-n3 btn-sm"
								onClick={() => setManageCoursesOpen(true)}
								icon={<IconAdd className="icon-22" />}>
								{t('courses.manage_courses')}
							</Button>
						}
					</div>
				</div>
				<div className="row flex-grow-1">
					<div className={`${isManageCoursesOpen ? 'col-6' : 'col'} px-0 d-flex`}>
						<Container
							isLoading={loadingCourse}
							isEmpty={courses?.length === 0}
							emptymessage={t('courses.student_no_courses')}
							descriptionmessage={t('courses.description_student_no_courses')}
							widthdescription="w-50"
						>

							<DataTable columns={columns()} data={courses} resizable={true} sortable={true} loading={loadingCourse} className="bg-white" />
						</Container>
					</div>
					{isManageCoursesOpen && (
						<div className="col-6 border-start px-0 ">
							<DataTable columns={columns(true)} data={unassignedCourses} resizable={true} sortable={false} loading={loadingCourse} className="bg-white" />
						</div>
					)}
				</div>
			</div>
			<ModalOverlay
				isOpen={showWarningModal || blocker.state === 'blocked'}
				modalSize="md"
				onRequestClose={() => setShowWarningModal(false)}
				headerChildren={t('discard_changes')}
				confirmButtonChildren={t('confirm')}
				cancelButtonChildren={t('cancel')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					if (blocker.state === 'blocked') {
						blocker.proceed?.();
					} else {
						fetchCourseByStudentId(parseInt(studentId));
					}
					setIsDirty(false);
					setShowWarningModal(false);
					setManageCoursesOpen(false);
				}}
				cancelButtonAction={() => {
					if (blocker.state === 'blocked') {
						blocker.reset?.();
					}
					setShowWarningModal(false);
				}}
			>
				{t('discard_changes_message')}
			</ModalOverlay>
		</React.Fragment>
	);
});

export default StudentCourses;
