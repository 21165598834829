// File: Wip.jsx
import React, { useEffect, useState } from 'react';
import { Button, Container, LoadingScreen, Page } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { IconArrowLeft, IconCheckCircleActivity, IconEmptyCircleActivity, IconClose, IconSkipNext, IconRedWarning, IconChevronRight } from '@punchcard/core/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Resources from 'components/wip/Resources';
import classNames from 'classnames';
import DisplayPDF from './DisplayPDF';
import documentAPI from 'api/documentsAPI';
import { FormProvider, useForm } from 'react-hook-form';

function Wip() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { courseId, unitId, studentId } = useParams();
	const [course, setCourse] = useState<TeacherCourseDTO>();
	const [loading, setLoading] = useState<boolean>(true);
	const [activeUnit, setActiveUnit] = React.useState<number>(0);
	const [currentActivity, setCurrentActivity] = useState<currentActivityAssessment | undefined>();
	const [rtl, setRtl] = useState<boolean>(false);
	const [collapse, setCollapse] = useState<boolean>(true);
	const safeStatuses = ['InProgress', 'Open', 'ReAttempt', ''];
	const isActivityOpen = (currentActivity && (currentActivity?.item?.courseActivityStatus == null || safeStatuses.includes(currentActivity?.item?.courseActivityStatus.statusCode))) ?? true;
	const form = useForm<WIPForm>({
		defaultValues: {
			undoHistory: [],
			undoIndex: null,
			lastAction: null,
		},
	});

	useEffect(() => {
		async function fetchCourse(courseId: string, studentId: string) {
			try {
				setLoading(true);
				const documents = await documentAPI.getStudentCourseUnit(courseId, studentId);
				setCourse(documents);
				setLoading(false);
			} catch (error) {
				console.error('error fetching courses', error);
				setLoading(false);
			}
		}
		if (courseId && studentId) {
			fetchCourse(courseId, studentId);
		}
	}, [courseId, studentId]);

	if (loading) {
		return <LoadingScreen />;
	}

	const handleActivityChange = (item: currentActivityAssessment) => {
		setCurrentActivity(item);
		return true;
	};
	async function onStatusChange(courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) {
		if (studentId && courseActivityId && status) {
			const course = await documentAPI.postCourseActivityStatus(courseActivityId, studentId, status, type, gradeReceived);
			setCurrentActivity((prev) => {
				if (prev) {
					return {
						...prev,
						item: {
							...prev.item,
							gradeReceived: gradeReceived,
							courseActivityStatus: {
								...prev.item.courseActivityStatus,
								statusCode: status,  // Update the statusCode inside courseActivityStatus
							},
						},
					};
				}
				return prev;
			});
			setCourse(course);
		}
	}

	const statusCodeMappingActivity: statusCodeMappingActivity = {
		'Open': {
			icon: IconEmptyCircleActivity,
			className: 'text-gray-600',
			status: t('activity_status.open'),
			clickable: false,
			activeClassName: 'text-gray-600'
		},
		'InProgress': {
			icon: IconEmptyCircleActivity,
			className: 'text-dark',
			status: t('activity_status.in_progress'),
			clickable: false,
			activeClassName: 'text-dark'
		},
		'ReAttempt': {
			icon: IconClose,
			className: 'text-gray-800',
			status: t('activity_status.reattempt'),
			clickable: false,
			activeClassName: 'text-gray-800'
		},
		'ReadyForReview': {
			icon: IconRedWarning,
			className: 'text-danger-600',
			status: t('activity_status.ready_for_review'),
			clickable: true,
			activeClassName: 'ext-danger-600'
		},
		'Completed': {
			icon: IconCheckCircleActivity,
			className: 'text-success-600',
			status: t('activity_status.completed'),
			clickable: false,
			activeClassName: 'text-success-600'
		},
		'Skipped': {
			icon: IconSkipNext,
			className: 'text-gray-800',
			status: t('activity_status.skipped'),
			clickable: false,
			activeClassName: 'text-gray-800'
		}
	};

	return (
		<Page className="bg-light flex-column scroll-container-wrapper border-gray-500 border-start" scrollable={false}>
			<header className="px-3 py-2 d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					<div className="pe-3 me-3 border-end">
						<Button className="btn-ghost-primary" icon={<IconArrowLeft />} onClick={() => navigate(-1)}>{t('back')}</Button>
					</div>
					<h6>
						{course?.courseName ? course?.courseName : t('wip.course')}
						<IconChevronRight className="icon-27" />
						{course?.courseUnits && course?.courseUnits[0]?.unitName ? course?.courseUnits[0]?.unitName : t('wip.unit')}
					</h6>
				</div>
			</header>
			<div className="overflow-hidden flex-fill position-relative">
				<Container className={classNames('m-0 scroll-container-wrapper justify-content-center', rtl && 'flex-row-reverse')}>
					<Resources
						studentId={studentId}
						course={course}
						rtl={rtl}
						collapse={collapse}
						setCollapse={setCollapse}
						courseId={courseId}
						setCurrentActivity={(activity) => handleActivityChange(activity)}
						statusCodeMappingActivity={statusCodeMappingActivity}
						safeStatuses={safeStatuses}
						activeUnit={activeUnit}
						setActiveUnit={setActiveUnit}
					/>
					<div className={classNames('reader', rtl ? 'me-3' : 'ms-3', collapse && 'wip')}>
						<FormProvider {...form}>
							<DisplayPDF
								studentId={studentId}
								courseId={courseId}
								courseUnitId={unitId}
								submitDrawing={() => false}
								currentActivity={currentActivity}
								statusCodeMappingActivity={statusCodeMappingActivity}
								onStatusChange={onStatusChange}
								isActivityOpen={isActivityOpen}
							/>
						</FormProvider>
					</div>
				</Container>
			</div>
		</Page>
	);
}

export default Wip;
