import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: 'en-US',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			'en-US': {
				translation: {
					welcome_lyla: 'Welcome to LYLA Teacher',
					loading: 'Loading...',
					back: 'Back',
					view: 'View',
					cancel: 'Cancel',
					confirm: 'Confirm',
					save: 'Save',
					add: 'Add',
					remove: 'Remove',
					expand_all: 'Expand all',
					edit: 'Edit',
					discard_changes: 'Discard changes?',
					discard_changes_message: 'You have unsaved changes. Are you sure you would like to discard them?',
					this_field_is_required: 'This field is required',
					sidebar: {
						home: 'Home',
						student: 'Student',
						logout: 'Logout',
						collapse: 'Collapse',
						settings: 'SETTINGS',
						sites: 'Sites',
					},
					students: {
						your_students: 'Your Students',
						students: 'Students',
						no_students: 'Your roster is empty',
						description_no_students: 'To manage students, add them to your roster',
						student_not_found: 'Student not found',
						description_not_found: 'The student you are looking for does not exist.',
						date_of_birth: 'Date of Birth',
						SSID: 'SSID',
					},
					pagination: {
						page: 'Page',
						of: 'of',
					},
					navigation: {
						back_to_all_students: 'Back to all students',
						close: 'Close',
						back: 'Back',
						save: 'Save',
						delete: 'Delete',
					},
					courses: {
						all_courses: 'All courses',
						details: 'Details',
						student_no_courses: 'Your student has no courses',
						description_student_no_courses: 'This student currently has no courses. To add courses, click the \'Manage courses\' button.',
						courses: 'Courses',
						course_name: 'Course Name',
						course_id: 'Course ID',
						code: 'Code',
						manage_courses: 'Manage courses',
						students_courses: 'Student\'s courses',
					},
					units: {
						back_to_courses: 'Back to courses',
						view_work: 'View Work',
						unit: 'Unit',
						units: 'Units',
						details: 'Details',
						no_units_for_this_course: 'No units for this course',
						description_no_units_for_this_course: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin venenatis leo sed.',
					},
					error: {
						message404: 'Page not found',
						description404: 'Oops! The page you\'re looking for can\'t be found.',
						message403: 'Access Denied',
						description403: 'We\'re sorry, but it looks like you don\'t have permission to view this page.',
						back_to_home_page: 'Back to home page',
						grade_cannot_exceed_maximum: 'Grade cannot exceed the total grade value',
						this_field_is_required: 'This field is required',
					},
					wip: {
						course: 'Course',
						unit: 'Unit',
						no_resources_for_this_unit: 'No resources for this unit',
						description_no_resources_for_this_unit: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin venenatis leo sed.',
						collapse: 'Collapse',
						resources: 'Resources',
						needs_redo: 'Needs redo',
						pass: 'Pass',
					},
					activity_status: {
						open: 'Not started',
						in_progress: 'In progress',
						reattempt: 'Needs redo',
						ready_for_review: 'Grade',
						completed: 'Passed',
						skipped: 'Skipped',
						returned_to_student: 'Returned to student',
					},
					lesson_status: {
						open: 'Not started',
						in_progress: 'In progress',
						reattempt: 'In progress',
						completed: 'Passed',
					},
					educators: {
						roster: 'Roster',
						educators: 'Educators',
						no_students: 'No students',
						description_no_students: 'This educator currently has no students. To add students, click the "Manage Roster" button.',
						description_no_students_manage: 'This educator currently has no students. To add students, click the "+" icon beside a student’s name.',
						no_educators: 'No educators',
						description_no_educators: 'There is no educator on this school.',
						manage_roster: 'Manage Roster',
						student_roster: 'Student Roster',
						name: 'Name',
						nb_students: '# of students',
						unable_to_retrieve_educators_list: 'Unable to retrieve educators list',
						all_students: 'All Students',
						back_to_all_educators: 'Back to all educators',
						educator_not_found: 'Educator not found',
						description_educator_not_found: 'The educator you are looking for does not exist.',
					},
					sites: {
						sites: 'Sites',
						add_site: 'Add Site',
						name: 'Name',
						description: 'Description',
						edit_site: 'Edit Site',
						delete: 'Delete',
						delete_site: 'Delete Site',
						want_delete_site: 'Are you sure you want to delete {{siteName}}?',
						no_site_on_school: 'No sites found on {{schoolName}}.',
						no_students: 'No students',
						no_students_on_site: 'No students found on {{siteName}}.',
						userType: 'User Type',
						unable_to_retrieve_school_sites: 'Unable to retrieve school sites',
						unable_to_retrieve_site_students: 'Unable to retrieve site students.',
						successfully_deleted_site: 'Successfully deleted site.',
						successfully_added_site: 'Successfully added site.',
						successfully_updated_site: 'Successfully updated site.',
						unable_to_delete_site: 'Unable to delete site.',
						unable_to_add_site: 'Unable to add site.',
						unable_to_update_site: 'Unable to update site.',
						your_students: 'Your students',
						remove_student: 'Remove student',
						remove_student_message: 'Are you sure you want to remove {{studentName}} from {{siteName}}?',
						successfully_removed_student: 'Successfully removed student.',
						unable_to_remove_student: 'Unable to remove student.',
						select_students: 'Select students',
						students: 'Students',
						select_at_least_one_student: 'Please select at least one student.',
						successfully_added_students: 'Successfully added student(s).',
						unable_to_add_students: 'Unable to add student(s).',
						status: 'Status',
						email_address: 'Email',
						no_sites: 'No sites',
					},
					termsAndConditions: {
						terms_of_service: 'Terms of Service',
						agree_to_terms: 'By clicking "Continue" you agree to the ',
						terms_of_service_link: 'Terms of Service',
						continue: 'Continue',
					},
				}
			}
		}
	});

export default i18n;