import axios from 'axios';

const studentsApi = {

	getStudents: async (): Promise<TeacherStudentDTO[]> => {
		return axios.get('/teacher/students')
			.then(response => response.data as TeacherStudentDTO[]);
	},
	getStudentsForTeacher: async (): Promise<TeacherStudentDTO[]> => {
		return axios.get('/teacher/studentsforteacher')
			.then(response => response.data as TeacherStudentDTO[]);
	},
};

export default studentsApi;