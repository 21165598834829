import React, { useContext, useEffect, useState } from 'react';
import { IconChevronLeft, IconChevronRight } from '@punchcard/core/icons';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '@punchcard/core';
import { StudentContext } from 'context/StudentContext';

interface IProps {
	items: Item[];
	title: string;
}
interface Item {
	id: number;
	name: string;
	to: string;
}

const ITEMS_PER_PAGE = 10;

const SecondSideBar = (props: IProps) => {
	const { items, title } = props;
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState<number>(1);

	const comparePaths = (to: string, pathname: string): boolean => {
		const indexTo = to.indexOf('?');
		const toWithoutParams = indexTo === -1 ? to : to.substring(0, indexTo);

		const indexPathName = pathname.indexOf('?');
		const pathNameWithoutParams = indexPathName === -1 ? pathname : pathname.substring(0, indexPathName);

		return toWithoutParams === pathNameWithoutParams;
	};

	useEffect(() => {
		const activeItem = items.find(item => comparePaths(item.to, location.pathname));

		if (activeItem) {
			const activeIndex = items.indexOf(activeItem);
			const page = Math.ceil((activeIndex + 1) / ITEMS_PER_PAGE);
			setCurrentPage(page);
		}
	}, [location.pathname, items]);

	const indexOfLastItem: number = currentPage * ITEMS_PER_PAGE;
	const indexOfFirstItem: number = indexOfLastItem - ITEMS_PER_PAGE;
	const currentItem: Item[] = items?.length > 0 ? items.slice(indexOfFirstItem, indexOfLastItem) : [];

	const paginate = (pageNumber: number): void => setCurrentPage(pageNumber);

	return (
		<nav className="sidebar secondary-sidebar bg-light border-end border-gray-300" aria-label="Main Navigation" role="navigation">
			<div className="d-flex flex-column p-2 pt-3 secondary-sidebar">
				<div className="d-flex align-items-center mb-3">
					<h6>{title}</h6>
				</div>
				<ul className="nav flex-column">
					{currentItem.map((item: Item) => (
						<li className="nav-item rounded w-100" key={item.id}>
							<Link
								className="nav-link text-dark p-0 rounded"
								to={item.to}
								state={{ name: item.name }}
							>
								<div className={classNames(
									'sidebar-item rounded p-2 overflow-hidden text-nowrap text-truncate',
									{ 'active': comparePaths(item.to, location.pathname) }
								)}
								>
									{item.name}
								</div>
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div>
				<div className="p-2 d-flex justify-content-start">
					<Button
						className="btn-ghost-primary ms-2 p-0"
						onClick={() => paginate(currentPage - 1)}
						aria-label="Previous Page"
						disabled={currentPage === 1}
					>
						<IconChevronLeft className="icon-22"/>
					</Button>
					<Button
						className="btn-icon btn-ghost-primary ms-2 p-0"
						onClick={() => paginate(currentPage + 1)}
						aria-label="Next Page"
						disabled={indexOfLastItem >= items.length}
					>
						<IconChevronRight className="icon-22"/>
					</Button>
				</div>
			</div>
		</nav>
	);
};

export default SecondSideBar;
